
@media print {
    nav, footer, button {
        display: none;
    }

    .uw-header, .uw-main-nav, .uw-secondary-nav, .uw-global-bar, .uw-header-search, .uw-button {
        display: none;
    }
}
